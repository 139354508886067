import Hyperlink from '../HyperLink'
import PropTypes from 'prop-types'
import TrustpilotDesign from '../TrustpilotDesign'
import { useTrustMarkers } from 'src/common/trust-markers'

const TrustpilotItem = ({
  className,
  trustpilotClassName,
  trustScoreClassName,
  trustpilotRatingClassName,
  starClassName,
  starsGroupClassName,
  starsGroupImgClassName,
  isHome = false,
  shortText = false,
  detailsText = false,
  disableRedirect = false,
  truspilotReviews,
  truspilotScore,
}) => {
  const { getTrustMarkerById } = useTrustMarkers()
  const trustPilot = getTrustMarkerById('truspilot')

  if (disableRedirect) {
    return (
      <TrustpilotDesign
        className={className}
        trustpilotClassName={trustpilotClassName}
        trustScoreClassName={trustScoreClassName}
        trustpilotRatingClassName={trustpilotRatingClassName}
        starClassName={starClassName}
        starsGroupClassName={starsGroupClassName}
        starsGroupImgClassName={starsGroupImgClassName}
        isHome={isHome}
        detailsText={detailsText}
        shortText={shortText}
        truspilotReviews={trustPilot?.totalReview || truspilotReviews}
        truspilotScore={Number(trustPilot?.rating || truspilotScore)}
      />
    )
  }

  return (
    <Hyperlink
      href="https://www.trustpilot.com/review/freedomdebtrelief.com"
      target="_blank"
      rel={`noreferrer`}
      clickTracking={true}
      clickTrackText="Trustpilot"
    >
      <TrustpilotDesign
        className={className}
        trustpilotClassName={trustpilotClassName}
        trustScoreClassName={trustScoreClassName}
        trustpilotRatingClassName={trustpilotRatingClassName}
        starClassName={starClassName}
        starsGroupClassName={starsGroupClassName}
        starsGroupImgClassName={starsGroupImgClassName}
        isHome={isHome}
        detailsText={detailsText}
        shortText={shortText}
        truspilotReviews={trustPilot?.totalReview || truspilotReviews}
        truspilotScore={Number(trustPilot?.rating || truspilotScore)}
      />
      {/* <div
        className={classNames(
          'flex flex-row items-center justify-center',
          className
        )}
      >
        <Img
          src="/next-assets/trustpilot/big-star.webp"
          priority
          alt="Trustpilot 4.5 star average rating on over 38,000 reviews for Freedom Debt Relief"
          className={classNames('top-0', starClassName)}
        />
        <span
          className={classNames(
            'pl-1 pt-1 font-medium text-black-base',
            trustpilotClassName
          )}
        >
          Trustpilot
        </span>
        <Img
          src="/next-assets/trustpilot/trustpilot-rating.svg"
          alt="Trustpilot 4.5 star average rating on over 38,000 reviews for Freedom Debt Relief"
          className={starsGroupClassName}
          priority
          imgClassName={classNames('transform-none', starsGroupImgClassName)}
        />
        <span
          className={classNames(
            'pt-1 text-black-base',
            trustpilotRatingClassName
          )}
          data-testid={'trustpilot-rating'}
        >
          <span
            className={classNames(
              'text-text-black font-bold',
              trustScoreClassName
            )}
          >
            {trustpilotData.trustScore}
          </span>
          {shortText ? '/' : ' out of '}5
        </span>
      </div>
      {isHome ? (
        <span className="flex justify-center text-14 font-normal text-black-base">
          Reviews {reviewsNumber} · Excellent
        </span>
      ) : null} */}
    </Hyperlink>
  )
}

TrustpilotItem.propTypes = {
  className: PropTypes.string,
  trustpilotClassName: PropTypes.string,
  trustScoreClassName: PropTypes.string,
  trustpilotRatingClassName: PropTypes.string,
  starClassName: PropTypes.string,
  starsGroupClassName: PropTypes.string,
  starsGroupImgClassName: PropTypes.string,
  isHome: PropTypes.bool,
  shortText: PropTypes.bool,
}

export default TrustpilotItem
